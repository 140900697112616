import * as React from 'react'
import { Link } from 'gatsby'
import { container, heading, navLinks,
          navLinkItem, navLinkText,
          copyright, footer } from './layout.module.css'

const Layout = ({ pageTitle, children }) => {
  return (
    <div className={container}>
      <title>{pageTitle}</title>
      <nav>
        <ul className={navLinks}>
          <li className={navLinkItem}>
            <Link to="/" className={navLinkText}>
              Home
            </Link>
          </li>
          <li className={navLinkItem}>
            <Link to="/projects" className={navLinkText}>
              Projects
            </Link>
          </li>
          <li className={navLinkItem}>
            <Link to="/fundamentals" className={navLinkText}>
              Fundamentals
            </Link>
          </li>
          <li className={navLinkItem}>
            <Link to="/practices" className={navLinkText}>
              Practices
            </Link>
          </li>
          <li className={navLinkItem}>
            <Link to="/blog" className={navLinkText}>
              Blog
            </Link>
          </li>
        </ul>
      </nav>
      <main>
        <h1 className={heading}>{pageTitle}</h1>
        {children}
      </main>
      <footer className={footer}>
        <p className={copyright}>&copy; 2022 Coded Environment</p>
      </footer>
    </div>
  )
}

export default Layout;
