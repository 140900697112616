import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const FundamentalsPage = () => {
  return (
    <Layout pageTitle="Fundamentals">
      <p>Knowledge fragment　知識の断片</p>
      <h2>Geometry</h2>
      <Link to="/fundamentals/stl01">
        stlファイルフォーマット
      </Link>
      <h2>Psychrometrics</h2>
      湿り空気の状態の計算について
      <ul>
        <li>
          密度、比容積
        </li>
        <li>
          飽和水蒸気圧
        </li>
        <li>
          水蒸気分圧、相対湿度、絶対湿度
        </li>
        <li>
          比エンタルピ
        </li>      
        <li>
          湿球温度、露点温度
        </li>
        <li>
          計算フロー
        </li>
      </ul>
    </Layout>
  )
}
export default FundamentalsPage
